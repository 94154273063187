var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.params.data.isCredentialsInvalid
    ? _c(
        "div",
        [
          _c("feather-icon", {
            attrs: {
              title: "Invalid Credentials",
              icon: "AlertTriangleIcon",
              svgClasses: "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }